<template>
    <div>
        <div>
            <div class="row border-bottom mb-4 pb-2" v-if="showNewConsultantForm">
                <div class="col-lg-4" v-if="!selectedConsultant">
                    <div class="card border p-3 ">
                        <div class="mb-2 fw-bolder">Uzman seçiniz</div>
                        <input type="search" placeholder="Uzman adını yazarak arayabilirsiniz." v-model="searchText" @keyup="searchPerson($event)" class="form-control mb-2">
                        <div class="position-relative">
                            <div style="max-height:200px" class="w-100 scroll position-absolute p-4 rounded border bg-white" v-if="searchText">
                                <div class="person-item" v-for="(item, i) in alllConsultants" :key="i">
                                    <a href="" @click.prevent="selectPerson(item)" class="d-block p-2 border-bottom mb-1">{{ item.name+' '+item.surname }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center">
                    <div v-if="selectedConsultant" class="col-lg-3">
                        Seçilen Uzman:<br> <strong>{{ selectedConsultant.name }} {{ selectedConsultant.surname }}</strong>
                        <br>
                        <a href="" @click.prevent="resetSelection()">Vazgeç</a>
                    </div>

                    <div class="col-lg-4">
                        <input type="button" v-if="selectedConsultant" class="btn btn-primary" @click="addConsultant()" value="Ekle">
                    </div>

                </div>
            </div>
            
        </div>

        <div class="d-flex justify-content-start" @click.prevent="addNewCategoryConsultant()" v-if="!showNewConsultantForm">
            <button class="btn btn-primary">
                Yeni Ekle
            </button>
        </div>

        <table  v-if="categoryConsultants" class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
          <thead>
            <tr class="fw-bolder">
              <th>ID</th>
              <th>Adı</th>
              <th>Soyadı</th>
              <th>İşlemler</th>
            </tr>
          </thead>
            <tbody>

            <tr v-for="(item, i) in categoryConsultants" :key="i">
              <td>{{ item.userId }}</td>
              <td>{{ item.userName }}</td>
              <td>{{ item.userSurname }}</td>
              <td>
                <div class="dropdown">
                  <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" id="categoryConsultantsListActions" ref="categoryConsultantsListActions" data-bs-toggle="dropdown" aria-expanded="false">
                      İşlemler
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="categoryConsultantsListActions">                     
                      <li><a class="dropdown-item" @click.prevent="editCategoryConsultant(item)">Düzenle</a></li>
                      <li><a class="dropdown-item"  @click.prevent="removeCategoryConsultant(item)" href="#">Sil</a></li>
                      </ul>
                  </div>


              </td>
            </tr>
          </tbody>
        </table>
    </div>
</template>

<script>

import api from '@/core/services/ApiService'
import Swal from "sweetalert2/dist/sweetalert2.js";

export default {
    name:'ManageCategoryConsultant',
    props:['selectedCategory','activeConsultants','mode'],

    methods:{
        resetSelection(){
            this.selectedConsultant=false;
        },
        selectPerson(person){
            this.selectedConsultant = person;
        },
        searchPerson(e){
            let re = new RegExp(`${this.searchText}`, 'gi');            
            this.alllConsultants = this.activeConsultants.filter(item=>{return item.name.match(re) || item.surname.match(re)});
            

        },
        addConsultant(){
            let url = `/User/Category/AddUser/${this.selectedCategory.categoryId}/${this.selectedConsultant.userId}/${(this.categoryConsultants.length)+1}`;
            api.get(url).then((res)=>{
                Swal.fire({
                    icon:'success',
                    title:'Başarılı',
                    text:`Danışman ${this.selectedCategory.categoryName} kategorisine eklendi`
                    
                }).then(()=>{
                    window.location.reload();
                })
            });
        },
        addNewCategoryConsultant(){
            this.showNewConsultantForm = true;
        },

        removeCategoryConsultant(item){
        Swal.fire({
          icon:'warning',
          title:'Eimn misiniz?',
          html:'<strong>'+(item.userName+' '+item.userSurname)+ '</strong> isimli uzmanı, <strong>'+this.selectedCategory.categoryName+'</strong> kategoriden çıkartmak istediğinize emin  misiniz?',
          showCancelButton:true
        }).then((res)=>{
          if(res.isConfirmed){
            api.get(`/User/Category/RemoveUser/${item.categoryId}/${item.userId}`).then((res)=>{
                Swal.fire({
                    icon:'success',
                    title:'Başarılı',
                    text:`Danışman, ${this.selectedCategory.categoryName} kategorisinden çıkarıldı`
                    
                }).then(()=>{
                    window.location.reload();
                })
            });
          }
        })
        
      },

    },

    data(){
        return {
            showNewConsultantForm:false,
            categoryConsultants:false,
            searchText:null,
            alllConsultants:[],
            selectedConsultant:false,
        }
    },
   

    created(){
        this.alllConsultants = this.activeConsultants;

        api.get("/User/Category/ListUser/"+this.selectedCategory.categoryId).then((res)=>{
          this.categoryConsultants = res.data;  
          let ids = this.categoryConsultants.map((item)=>{return item.userId});
          
          this.alllConsultants = this.alllConsultants.filter((item)=>{return !ids.includes(item.userId)});
          
        });
        

    }
        
}
</script>