<template>
  <div class="card mb-5 mb-xl-12">
    <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
      
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">Kategoriler</h3>

        
      </div>      
    </div>
    
    

    <div id="kt_account_profile_details" class="collapse show" v-if="categories">

        <div id="package-detail" v-if="showDetail">
            <div class="card-body border-top p-9">
                
                <CategoryDetalForm :selectedCategory="selectedCategory" :mode="mode" v-if="showCategoryForm && selectedCategory"  />
                <ManageCategoryConsultant :selectedCategory="selectedCategory" :activeConsultants="activeConsultants" :mode="mode" v-if="showManageConsultants && selectedCategory"  />
                

            </div>

            <!--begin::Actions-->
            <div class="card-footer d-flex justify-content-end py-6 px-9">
                <button type="reset" @click.prevent="cancelForm()" class="btn btn-white btn-active-light-primary me-2">Kategori listesine geri dön</button>

                <button type="submit" id="kt_account_profile_details_submit" ref="submitButton1" v-if="showCategoryForm" @click.prevent="updateCategory()" class="btn btn-primary">
                    <span class="indicator-label"> Kaydet </span>
                    <span class="indicator-progress">
                    Lütfen bekleyin...
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                </button>
            </div>
            <!--end::Actions-->
        </div>


        <div class="card-body border-top p-9" v-show="!showDetail">
            <div class="table-responsive">
                <a href="" @click.prevent="addCategory()" class="btn btn-primary float-right">Yeni Kategori Ekle</a>
              <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                <thead>
                    <tr class="fw-bolder">
                    <th>Sıra</th>
                    <th>Kategori Adı</th>
                    <th>Kategori Başlığı</th>
                    <th>Açıklama</th>
                    <th>Renkler</th>
                    <th>Durum</th>                    
                    
                    <th>İşlem</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in categories" :key="i">
                    <td>{{item.orderNo}}</td>
                    <td>{{item.categoryName}}</td>
                    <td>{{item.title}}</td>
                    <td>{{item.description}}</td>
                    <td>
                      <div class="d-flex align-items-center">
                        <div class="color-wrapper">
                           <div class="color-box" :style="'background-color:'+item.color1"></div>
                           <div class="color-code" :style="'border:1px solid '+item.color1">{{ item.color1 }}</div>
                        </div>
                        <div v-if="item.color2">
                          <div class="color-wrapper">
                            <div class="color-box" :style="'background-color:'+item.color2"></div>
                            <div class="color-code" :style="'border:1px solid '+item.color2">{{ item.color2 }}</div>
                          </div>
                        </div>
                      </div>  
                      
                        

                    </td>
                    <td><span :class="'badge badge-light-'+statusses.class[item.status]">{{statusses.label[item.status]}}</span></td>
                    <td><ActionsMenu :item="item" :packagekey="i" @consultants="manageConsultants($event)"  @edit="editCategory($event)" @remove="removeCategory($event)" /></td>
                    </tr>            
                </tbody>
              </table>
      
            </div>
        </div>
    </div>
    
  </div>


</template>



<script>
import api from '@/core/services/ApiService'
import ActionsMenu from "@/components/dropdown/CategoryListActions.vue";
import PackageActionToolbar from "@/components/actions/PackageToolbar.vue";
import CategoryDetalForm from '@/components/modals/forms/EditCategory.vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ManageCategoryConsultant from '@/components/modals/forms/ManageCategoryConsultant.vue';


const newCategoryValues={
    categoryName:null,
    status:null,
    color1:null,
    color2:null,
    IconPath:'/Images/Icons/logo-white.svg',
    orderNo:null,
}

export default  {
  name:'PackageGroups',
  components:{
      ActionsMenu,
      CategoryDetalForm,
      ManageCategoryConsultant      
  },
  data(){
    
    return{        
      selectedCategory:newCategoryValues,
      activeConsultants:[],
      showManageConsultants:false,
      showCategoryForm:false,
      categoryConsultants: [],
        mode:'new',

      showDetail:false,
      
      statusses : {
        class:{
          1:'success',
          0:'danger'
        },
        label:{
          1:'Aktif',
          0:'Pasif'

        }
      },
       planned : {
        class:{
          1:'success',
          0:'danger'
        },
        label:{
          1:'Evet',
          0:'Hayır'

        }
      },
      categories:null,
      

    }
  },

  

  methods:{
      editCategory(item){
          this.showDetail=true;
          this.mode='edit';
          this.showCategoryForm=true;
          this.selectedCategory = item;
          this.showManageConsultants=false;
      },

      manageConsultants(item){
        
        this.showManageConsultants=true;
        this.showDetail = true;
        this.showCategoryForm=false;
        this.selectedCategory = item;        
        
      },

      cancelForm(){
          this.showDetail=false;
      },

      addCategory(){
          this.mode='new';
          this.selectedCategory = newCategoryValues;  
          this.showCategoryForm=true;      
          this.showDetail = true;
          this.showManageConsultants=false;

      },
      
      
      removeCategory(item){
        
        Swal.fire({
          icon:'warning',
          title:'Kategoriyi silmek üzeresiniz',
          text:item.categoryName +' kategorisini silmek istediğinize emin misiniz?\nBu işlemin geri dönüşü yoktur!',
          showCancelButton:true
        }).then((result)=>{
          if(result.isConfirmed){
            api.get('/User/DeleteCategory/'+item.categoryId).then((res)=>{
              Swal.fire({
                title:'Kategori Silme',
                icon:'success',
                text:'Kategori silindi',
              }).then(()=>{
                window.location.reload();
              })
            });
          }else{
            
          }
        });
      },

      updateCategory(){
          let apiAddress = this.mode=='new' ? '/User/NewCategory' : '/User/UpdateCategory';
          this.selectedCategory.orderNo = parseInt(this.selectedCategory.orderNo);
          this.selectedCategory.status = parseInt(this.selectedCategory.status);

         


          api.post(apiAddress,this.selectedCategory).then((res)=>{
              Swal.fire({
                  text:'Paket grubu bilgileri güncellendi',
                  icon:'success',
              }).then(()=>{
                  window.location.reload();
              });
          }).catch((err)=>{
              console.log(err);
          })
      }
  },


  created(){
      let aComponent = {component:PackageActionToolbar, props:{},events:['addPackage']};
     setCurrentPageBreadcrumbs("Kategori listesi", ["Kategoriler"]);
     api.get('/User/ListUsers/JJ/6/0/100/1/-/2/JJ').then((res)=>{
      this.activeConsultants = res.data.listUser;      

        
          
          
        
     })
     api.get('/User/ListCategory/99?pageSize=10&page=1').then((res)=>{
         this.categories = res.data;
     });
   
  },

}

</script>
<style>

input:disabled{cursor: not-allowed; background-color: #eee;}
.color-wrapper{width:80px; display: inline-block; text-align: center; margin-right: 5px; position:relative;}
.color-box{width:100%; height: 20px;}
</style>