<template>
    <div>
        <div class="row">
            <div class="col-lg-9">
                <div class="row mb-10">
                    <div class="row">
                        <div class="col-lg-12 fv-row">
                            <label class="col-form-label">Kategori adı</label>                            
                            <input type="text" name="name" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" v-model="selectedCategory.categoryName" />
                        </div>
                    </div>
                </div>

                <div class="row mb-10">
                    <div class="row">
                        <div class="col-lg-12 fv-row">
                            <label class="col-form-label">Kategori başlığı</label>                            
                            <input type="text" name="title" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" v-model="selectedCategory.title" />
                        </div>
                    </div>
                </div>

                <div class="row mb-10">
                    <div class="row">
                        <div class="col-lg-12 fv-row">
                            <label class="col-form-label">Açıklama</label>                            
                            <textarea name="description"  class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" v-model="selectedCategory.description" />
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 fv-row">
                        <label class="col-form-label">Durum</label> 
                            <div class="d-flex align-items-center mt-3">
                            <label class="form-check form-check-inline form-check-solid me-5">
                                <input class="form-check-input" value="1" name="status" :checked="selectedCategory.status == '1'" type="radio" v-model="selectedCategory.status" />
                                <span class="fw-bold ps-2 fs-6"> Aktif </span>
                            </label>

                            <label class="form-check form-check-inline form-check-solid">
                                <input class="form-check-input"  value="0" name="status" :checked="selectedCategory.status == '0'" type="radio" v-model="selectedCategory.status" />
                                <span class="fw-bold ps-2 fs-6"> Pasif </span>
                            </label>
                        </div>
                    </div>

                    <div class="col-lg-4 fv-row">
                <label class="col-form-label">Renkler</label>
                <div class="d-flex">
                    <div class="d-flex align-items-center me-3">
                        <span class="me-2">
                            Renk 1 
                        </span>
                        <input type="color" name="color1" v-model="selectedCategory.color1" id="color1">
                    </div>

                    <div class="d-flex align-items-center">
                        <span class="me-2">
                            Renk 2 
                        </span>
                        <input type="color" name="color2" v-model="selectedCategory.color2" id="color2">
                    </div>
                </div>
                
            </div>

                    <div class="col-lg-2 fv-row">
                        <label class="col-form-label">Sıra</label>                                         
                        <input type="text" name="name" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" v-model="selectedCategory.orderNo" />
                    </div>
                </div>

                
                
            </div>
        </div>

    </div>
</template>

<script>

export default {
    name:'EditCategory',
    props:['selectedCategory','mode'],
   
        
}
</script>